import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "company": "Huawei",
  "title": "Compiler Software Engineering Intern",
  "time": "Fall 2020",
  "index": 2,
  "type": "position",
  "hidden": false,
  "splash": "Building out a heterogeneous optimizing compiler for GPU shaders and GPU compute workloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://www.huawei.com/"
      }}>{`Huawei Technologies`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` Sept - Dec 2020`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`C++`}</inlineCode>{` `}<inlineCode parentName="p">{`LLVM`}</inlineCode>{` `}<inlineCode parentName="p">{`OpenCL`}</inlineCode>{` `}<inlineCode parentName="p">{`Bash`}</inlineCode></p>
    <p><strong parentName="p">{`Location:`}</strong>{` Ontario, Canada`}</p>
    <p><strong parentName="p">{`Team:`}</strong>{` Mobile GPU Compilers`}</p>
    <hr></hr>
    <p>{`At Huawei I was on the `}<em parentName="p">{`Mobile GPU Compiler team`}</em>{` where I worked on our LLVM based GPU compiler. My team was targeting performance boosts for General Purpose GPU workloads such as compute kernels written in OpenCL. Over the course of the term I worked on the compiler backend which generates object files / assembly code from the optimized intermediate representation produced by the LLVM optimizer.`}</p>
    <p>{`I worked specifically on modifications to the object file format produced, resulting in a 66% power reduction on program load, and vastly improved binary tooling.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      